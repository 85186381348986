import axiosApiInstance from '@config/axios';

export const API = axiosApiInstance;
export const BASE_URL = process.env.REACT_APP_BASE_URL;

export const RequestsPath = {
  checkAuth: 'user',
  login: 'token/',
  refresh: '/token/refresh',
  posts: 'post/posts',
  locations: 'dictionaries/location/',
  createMailling: 'post/create_mailing/',
  companyDetail: 'company/companies',
  companyComments: 'company',
  skills: 'dictionaries/skill',
  postSubscriptions: 'post/subscriptions/',
};
